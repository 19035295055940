import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { Button } from "components/buttons"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

  ${breakpoint.medium`
    padding: 0 20px;
  `}
`

const StyledRespiHeader = styled.section`
display: ${props => (props.viewAllowed ? "block" : "none")};
background-color: ${colors.grayLight};
padding: 60px 10px;
    
	${breakpoint.small`
		flex-wrap: nowrap;
		padding: 60px 10px;
	`}

  .block {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
 	gap: 10px 20px;
 	justify-content: center;
	}

   	.block__content, .block__material {
		width: 100%;
	
      h2 {
		font-size: 36px;
		text-align: center;
		margin-bottom: 20px;
 	    }

		h4 {
			font-size: 21px;
			margin-block: 10px;
			border-bottom: 1px solid ${colors.purpleLightBg};
			padding-bottom: 5px;		
		}
	}

	.orangeButton {
		background-color: ${colors.orange};
		color: ${colors.charcoal}!important;
		width: 100%;
		min-width: 200px;
		max-width: 330px;
		height: 45px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 12px 18px;
		border-radius: 100px;
		font-size: 15px;
		line-height: 20px;
		font-weight: 600;
		box-sizing: border-box;
		transition: all .3s ease;
  
  		&:hover {
		  background-color: ${colors.charcoal};
		  color: ${colors.white}!important;
		}
	}
  
	${Button} {
		min-width: 200px;
	}
`

class RespiHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			section: ""
		}
	}

	handleSectionChange = (selSection) => {
		const inactiveSections = document.querySelectorAll("[data-filter='respiratory'][data-section]")
		inactiveSections.forEach(section => {
			section.classList.remove("visibleBlock")
		})
		const activeSections = document.querySelectorAll("[data-section='"+selSection+"']")
		activeSections.forEach(section => {
			section.classList.add("visibleBlock")
		})
	}

	render = () => (
		<StyledRespiHeader
			id="respi-header"
			data-filter="respiratory" data-filter-type="devices"
		>
			<StyledContainer>
				<div className="block__content">
					<div className="block">
						<div><Button className="orangeButton" onClick={() => this.handleSectionChange("provider")}>Provider Education</Button></div>
						<div><Button className="orangeButton" onClick={() => this.handleSectionChange("experts")}>What the experts are saying</Button></div>
						<div><Button className="orangeButton" onClick={() => this.handleSectionChange("patient")}>Patient Education</Button></div>
						<div><Button className="orangeButton" onClick={() => this.handleSectionChange("billing")}>Coding and Operational Resources</Button></div>
						<div><Button className="orangeButton" onClick={() => this.handleSectionChange("enduser")}>End User Training</Button></div>
						<div><Button className="orangeButton" onClick={() => this.handleSectionChange("orderforms")}>Order Forms</Button></div>
					</div>
				</div>
			</StyledContainer>
		</StyledRespiHeader>
	)
}


export default RespiHeader